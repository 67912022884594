<template>
  <md-field
    v-if="nugget.multilanguages && nugget.multilanguages.length > 1"
    class="multilanguage-field"
  >
    <md-select v-model="selected_language" class="multilanguage-select">
      <md-option
        v-for="item in multilanguageSelectOptions"
        :key="item.language"
        :value="item.language"
      >
        {{ $t("language." + item.language) }}
      </md-option>
    </md-select>
  </md-field>
  <md-field v-else class="multilanguage-field" style="margin-bottom: 7px;">
    <div class="multilanguage-one-element">
      {{ $t("language." + nugget.language) }}
    </div>
  </md-field>
</template>
<script>
import router from "@/router";

export default {
  name: "NuggetMultilanguageSelect",
  props: ["nugget"],
  data() {
    return {
      multilanguageSelectOptions: null
    };
  },
  computed: {
    selected_language: {
      get() {
        return this.nugget.language;
      },
      set(newVal) {
        if (newVal) {
          const language = this.nugget.multilanguages.find(
            obj => obj.language === newVal
          );
          // redirect to the same page route
          router.push({
            name: this.$route.name,
            params: { nugget_id: language.nugget_id }
          });
        }
      }
    }
  },
  watch: {
    selected_language() {
      this.multilanguageSelectOptions = this.nugget.multilanguages;
      this.multilanguageSelectOptions.push({
        language: this.nugget.language,
        nugget_id: this.nugget.nugget_id
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/multilanguage.scss";
</style>
