<template>
  <div v-if="config.use_stats">
    <div class="rating-container">
      <a class="rating saved">
        <div class="star_container">
          <span
            v-for="i in max"
            v-bind:key="i"
            class="star"
            :class="{ checked: rate === max + 1 - i }"
          >
            <font-awesome-icon :icon="['fas', 'star']" />
          </span>
        </div>
      </a>
      <p class="sub_rating">
        Note: {{ this.rate }} <span>({{ this.user }} votes)</span>
      </p>
    </div>
  </div>
</template>

<script>
import config_mixin from "@/mixins/config_mixin";
import microlearning_mixin from "@/mixins/microlearning_mixin";

// Font Awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faStar);

/*
  Component to get average rate of the Nugget /5
*/

export default {
  name: "Rated",
  mixins: [config_mixin, microlearning_mixin],
  components: {
    FontAwesomeIcon
  },
  props: ["nugget", "rate", "user"],
  data: function() {
    return {
      max: 5,
    };
  }
};
</script>
<style scoped lang="scss">
.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-left: 0;
}

.rating {
  display: flex;
  justify-content: left;
}

.sub_rating {
  margin: 10px auto 0px auto;
  cursor: default;
  font-size: 1rem;
  font-weight: bold;
}

.sub_rating span {
  font-weight: lighter;
}

.big {
  font-size: 2.2em;
}

.medium {
  font-size: 1.2em;
}

.small {
  font-size: 0.7em;
}

.star {
  color: lightgrey;
  padding: 1px;
}

.star_container {
  display: flex;
  flex-direction: row-reverse;
}

.star.checked,
.star.checked ~ span {
  color: orange;
}
</style>
