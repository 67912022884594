<template>
  <div class="modal modal-bg modal-nugget-completion" :class="{ show: true }">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="md-card md-theme-default md-card-login">
          <div class="modal-body">
            <md-button
              type="button"
              class="md-simple md-grey md-close md-just-icon md-round modal-default-button"
              @click="close"
            >
              <md-icon>clear</md-icon>
            </md-button>
            <h2>{{ $t("nugget.rating.completed") }}</h2>
            <rating> </rating>

            <div class="learning-objectives">
              <div v-if="nugget.learning_outcomes.length == 1">
                <h3>{{ $t("nugget.rating.finish_learning_outcome") }}</h3>
              </div>
              <div v-if="nugget.learning_outcomes.length > 1">
                <h3>{{ $t("nugget.rating.finish_learning_outcomes") }}</h3>
              </div>
              <li v-for="item in nugget.learning_outcomes" :key="item">
                {{ item }}
              </li>
            </div>

            <div class="text-center">
              <div
                v-if="nugget.authors.length == 1"
                class="main nugget-category nugget-slider"
              >
                <nugget-slider
                  title="same_author_nuggets"
                  :center="true"
                  :limit="0"
                  :nugget_class="{ 'col-md-3': true }"
                  :params="{ authors: nugget.authors }"
                ></nugget-slider>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <div class="container button">
              <router-link
                class="nugget-view-link"
                :to="{
                  name: 'Home',
                  hash: '#app_top'
                }"
                @dragstart.native.prevent
              >
                <md-button type="button" class="md-success md-simple md-lg">
                  {{ $t("nugget.home_page") }}
                </md-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";
import NuggetSlider from "@/components/NuggetSlider";
import Rating from "./Rating.vue";

export default {
  name: "NuggetCompletionModal",
  mixins: [microlearning_mixin],
  props: {
    nugget: {}
  },
  components: {
    NuggetSlider,
    Rating
  },
  data: function() {
    return {
      initial: window.location.href
    };
  },
  mounted: function() {
    // To change the shared link url if the user comes from the detail page
    let url = window.location.href.split("/");
    if (url.at(-1) !== "view") {
      url.pop();
      this.initial = url.join("/") + "/view";
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/nugget_slider.scss";

.modal-nugget-completion .modal-container {
  max-width: 70% !important;
}
.modal-nugget-completion .md-close .md-button-content i {
  font-size: 32px !important;
}

.learning-objectives {
  width: 50%;
  margin: auto;
  text-align: left;
}
li {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .modal-nugget-completion .modal-container {
    max-width: none;
  }

  .modal-nugget-completion .md-card-content {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
